/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    blockquote: "blockquote",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Para copiar un sistema linux a otra partición es muy fácil, tendremos que usar un LiveCD con algun linux que conozcamos y nos sea fácil de usar, por eso yo he cojido el LiveCD de kubuntu, formateé con gparted la nueva partición que iba a utilizar para el linux. Monté las dos particiones. Y para copiarlas utilice este sencillo comando:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">$ cp -ax /media/viejo/* /media/nuevo/</code>"
    }
  })), "\n"), "\n", React.createElement(_components.p, null, "En el que los directorios viejo y nuevo son las particiones del linux viejo y la nueva recien formateada."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "-a: para que copie de la forma archivo, y recursivo."), "\n", React.createElement(_components.p, null, "-x: para que copie solo en función del sistema de archivos actual (para no entrar en bucles con las particiones montadas)"), "\n"), "\n", React.createElement(_components.p, null, "Espero que os sea útil. Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
